<template>
<div>
    <div class="row">
            <div class="col-md-4">
<!--                <img class="dango1" src="@/assets/img/dango01.jpg">-->
                <img class="dango4" src="@/assets/img/dango04.jpg">
            </div>

            <div class="col-md-8 text-content">
            <div>
                <h2 class="title">团子翻译器</h2>
                <h4 class="description">
                    一款基于OCR和翻译功能的实时屏幕区域翻译软件，还支持图片翻译
                </h4>
                <p>
                    推荐 win7及以上系统使用。不支持 mac、linux，不支持手机端<br>
                </p>
                <div class="dl_btn">
                    <Button size="large" type="primary" @click="dl" >
                        下载简易版安装包
<!--                        <span v-text="'V'+Version"></span>-->
                    </Button>
                    <Button size="large" type="primary" ghost @click="dl_2">
                        下载完整版安装包
<!--                        <span v-text="'V'+Version"></span>-->
                    </Button>
                    <div style="position: absolute; top: 225px; left: 295px" >
                        完整版内置本地OCR、朗读等本地模型
                    </div>
                </div>
                <div class="dl2_btn">
                    <a v-on:click="dl_past">下载免安装压缩包</a>
                </div>
                <Poptip>
                    <a id="qq_code">最新QQ交流群：{{qq_code}}</a>
                    <div slot="title">最新QQ交流群：{{qq_code}}</div>
                    <div slot="content">
                        <img id="img_qq" :src="qq_code_img">
                    </div>
                </Poptip>
                <br>
                <Poptip placement="bottom">
                    <a id="qq_code">客服娘QQ：774263348</a>
                    <div slot="title">客服娘QQ：774263348</div>
                    <div slot="content">
                        <img id="img_qq" src="@/assets/二维码s.png">
                    </div>
                </Poptip>
            </div>

        </div>

    </div>

    <div>
        <div class="col-md-6">
            <video :src="video_url" autoplay controls loop muted style="max-width: 1024px; width: 100%;" >
                似乎您的浏览器不支持 <code>video</code> 标签，不如换个浏览器试试？.
            </video>
            <p class="video-description">翻译器使用演示-屏幕区域实时翻译 (蓝框为翻译的区域，绿色字体为译文)</p>
        </div>
        <div class="col-md-6">
            <video :src="video_url2" autoplay controls loop muted style="max-width: 1024px; width: 100%;" >
                似乎您的浏览器不支持 <code>video</code> 标签，不如换个浏览器试试？.
            </video>
            <p class="video-description">翻译器使用演示-图片翻译 (自动识别图上外文，完成抠字后将译文回填图上)</p>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";

export default {
    name: "ContentMain",
    computed: {},
    mounted: async function () {
        // 取 public/data.json
        await this.getData()
        // console.log(this.envUrl)

        try {
            // 拉取配置中心
            let u = await this.getData2();
            // this.Version = u.latest_version
            this.DLLink = u.pc_download_now
            this.DLLink2 = u.pc_download_all
            this.DLPastLink = u.pc_download_past
            this.qq_code = u.qq_group_number
            this.qq_code_img = u.dango_qq_group
        } catch (e) {
            console.error(e)
        }
    },
    methods: {
        /*    DLLinkShuffle() {
              for (let i = this.DLLink.length; i > 0; i--) {
                const index = Math.floor(Math.random() * i);
                if (index !== i - 1) {
                  const tmp = this.DLLink[index];
                  this.DLLink[index] = this.DLLink[i - 1];
                  this.DLLink[i - 1] = tmp;
                }
              }
            },*/
        dl: function () {
            if (typeof window.orientation !== "undefined")
                return window.alert("请通过电脑浏览器下载");
            window.location.href = this.DLLink;
        },
        dl_past: function () {
            if (typeof window.orientation !== "undefined")
                return window.alert("请通过电脑浏览器下载");
            window.location.href = this.DLPastLink;
        },
        dl_2: function () {
            if (typeof window.orientation !== "undefined")
                return window.alert("请通过电脑浏览器下载");
            window.location.href = this.DLLink2;
        },
        getData() {
            return new Promise((resolve) => {
                axios.get("../data.json?t=" + new Date().getTime()).then((response) => {
                    let u = response.data
                    this.DLLoginFixLink = u.DLLoginFixLink[0]
                    this.video_url = u.video_url
                    this.video_url2 = u.video_url2
                    this.envUrl = u.env_url
                    // 萬一拉不到配置中心, 用data.json 兜底
                    this.Version = u.latest_version
                    this.DLLink = u.install_exe
                    this.DLPastLink = u.install_zip
                    this.qq_code = u.qq_group_number
                    this.qq_code_img = u.dango_qq_group
                    // (this.DLLinkShuffle());
                }).finally(() => {
                    resolve()
                })
            })
        },
        getData2() {
            // let envUrl = 'https://dangouserv2-dev.ap-sh.starivercs.cn/dango_user_v2/get_dict_info'
            return new Promise((resolve) => {
                fetch(this.envUrl, {
                    method: 'post',
                })
                .then((response) => {
                    return response.json();
                })
                .then((res) => {
                    if (res.Result?.DictInfo) {
                        let u = JSON.parse(res.Result?.DictInfo)
                        // console.log(u)
                        resolve(u)
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
            })
        },
    },
    data() {
        return {
            Version: "",
            DLLink: "",
            DLLink2: "",
            DLPastLink: "",
            DLLoginFixLink: "",
            video_url: "",
            video_url2: "",
            qq_code: "",
            qq_code_img: "",
            envUrl: ""
        };
    },
};
</script>
<style scoped>
.text-content {
    display: grid;
    /*text-align: center;*/
    /*margin-top: 20%;*/
}

.title {
    /*color: #f9c9d2;*/
    color: #2d8cf0;
    font-size: 42px;
    padding: 10px 0;
    letter-spacing: 2px;
    font-weight: 900;
    /*font-family: HuaKangYuanTiW7, cursive, Arial, sans-serif;*/
}

.description {
    /*color: #ffb6a4;*/
    color: #2d8cf0b0;
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    /*font-family: HuaKang5, HuaKang3, HuaKangYuanTiW7;*/
}

.dl_btn {
    margin: 20px 0 10px 0;
}

.dl_btn button {
    width: 240px;
    font-weight: bold;
    /*color: #fff;*/
    /*background-color: #ffd3e1;*/
    /*border-color: #eec1cf;*/
    margin-right: 40px;
}

.dl2_btn {
    text-decoration: underline;
    margin: 10px 0;
    font-size: 1.4rem;
}

.dl2_btn a {
    color: #312d2d;
    background-color: transparent;
    border: 0 solid transparent;

}

h2, h4 {
    /*text-align: center;*/
}

img.dango1 {
    width: 400px;
    opacity: 0.4;
    position: absolute;
    top: 250px;
    right: 0;
}

img.dango4 {
    width: 400px;
    opacity: 1;
}

#qq_code {
    margin: 10px 0;
}

img#img_qq {
    width: 300px;
}

p.video-description {
    margin: 10px 0 50px 0;
}


</style>
